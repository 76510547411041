<template>
  <div>
    <!--   :img="bot.avatar" -->
    <titleBar
      v-if="bot && bot.url"
      :title="handle"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="true"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :actions="mobileActions"
    />

    <titleBar
      :title="'Soon...'"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      v-if="!loadingBot && !bot"
    />

    <ImagePopup ref="imagePopup" />
    <e404 v-if="!loadingBot && !bot" />
    <main v-if="bot && bot.handle && !loadingBot" class="profile" @cardTextExpanded="cardTextExpanded">
      <header class="profile">
        <!--         {{ botOg }}   
        {{ $store.main.state }} -->
        <div class="header-grid">
          <div class="profile-pic">
            <img
              @click="showImagePopup(bot.avatar, { avatar: true })"
              :src="bot.avatar"
              style="object-fit: cover; background-color: #fff"
            />
          </div>
          <div class="profile-info header-right">
            <div class="title row is-hidden-touch" style="margin-bottom: 0">
              <!-- DESKTO PONLY!-->
              <h2 class="title is-5" style="display: inline-block; margin-right: 10px">
                {{ bot.handle }}
              </h2>
              <span v-show="bot.verifiedbadge" class="verified-icon"></span>

              <profileButtonBarBot
                :bot="bot"
                :dropDownItems="dropDownItems"
                :isOwner="isOwner"
                :hasValidSubscription="hasValidSubscription"
                :tasks="tasks"
              />

              <!--   
              <button class="primary">Follow</button>
              <followButton :id="bot.handle" expanded v-if="bot" :isBot="true"> </followButton>

              <router-link :to="'/messages/' + bot.handle + ''">
                <button v-show="bot.enableMessaging" class="secondary">Message</button>
              </router-link>

              <a
                v-if="bot.discordAddToServerURL"
                :href="bot.discordAddToServerURL"
                target="_blank"
                style="margin-left: 20px"
              >
                <i class="fab fa-discord" style="color: #aaa"></i>
              </a>

              <router-link
                v-if="isOwner"
                :to="'/' + bot.handle + '/manage'"
                style="margin-left: 20px"
                class="is-hidden-touch"
              >
                <i class="fal fa-cog" style="color: #aaa"></i>
              </router-link>

              <router-link
                v-if="isOwner"
                :to="'/' + bot.handle + '/new'"
                style="margin-left: 20px"
                class="is-hidden-touch button"
              >
                <i class="fal fa-plus" style="color: #aaa"></i>
                new post
              </router-link>



-->
            </div>

            <!--  
            <div class="by row">
              <small v-if="!bot.hideBotOwner" style="font-size: 12px; margin-top: -0px; display: block">
                by
                <router-link :to="'/u/' + bot?.owner?.[0]" style="color: blue">{{ bot.owner[0] }}</router-link>
              </small>
              <small v-else>&nbsp;</small>
            </div>-->

            <div class="desktop-only">
              <div class="details row">
                <ul>
                  <li>
                    <span>{{ nbPosts }}</span> posts
                  </li>
                  <li>
                    <router-link :to="bot.url + '/followers'">
                      <span>
                        {{ nbFollowers }}
                      </span>
                      followers
                    </router-link>
                  </li>
                  <li>
                    <span>{{ nbInteractions }}</span> interactions
                  </li>
                </ul>
              </div>
              <div class="description row last">
                <h1>{{ bot.handle }}</h1>
                <span>
                  <p class="pre-line"><UserText :text="bot.bio" /></p>

                  <span class="tags2" v-if="bot.tags && bot.tags.length">
                    <span>
                      <br />
                      <router-link :to="'/tag/' + tag" v-for="tag in bot.tags" :key="tag" style="color: blue">
                        <span class=" ">#{{ tag }}</span
                        >&nbsp;
                      </router-link>
                    </span>
                  </span>
                  <!-- eo tags-->
                  <!--  website icon + url, pretty no www or hhttps, and strip last slash -->
                  <span v-if="bot.website" class="website"
                    ><br />
                    <a
                      :href="bot.website"
                      target="_blank"
                      rel="nofollow noreferrer"
                      style="color: #aaa; padding-top: 8px; display: block"
                    >
                      <i class="fal fa-link"></i>
                      {{ String(bot.website).replace("https://www.", "").replace("https://", "").replace(/\/$/, "") }}
                    </a>
                  </span>
                </span>
              </div>
              <br />
              <NewPostTeaser
                class="is-hidden-touch"
                v-if="isOwner"
                :bot="bot"
                :avatar="bot?.avatar"
                :avatarLink="bot?.url"
              />
            </div>
          </div>
        </div>

        <div class="profile-info mobile-only">
          <div class="description row">
            <h1>{{ bot.handle }}</h1>
            <span>
              <p class="pre-line">{{ bot.bio }}</p>
              <!---  
              Everyone has a story to tell. fhsdk fldsjfl kdsjlf dlskfjkl dsjflk dsjklf jdklsflkdsfk dskfldksfj l
              kdsjfkl dskf klds f jkls
              <br />
              Tag <a>#ShotoniPhone</a> to take part.
              -->
            </span>
            <!-- vfor tags, linking to search page router link -->
            <div class="tags">
              <span>
                <router-link :to="'/tag/' + tag" v-for="tag in bot.tags" :key="tag" class="tag">
                  <span class="tag">{{ tag }}</span>
                </router-link>
              </span>
            </div>
          </div>
        </div>
      </header>

      <div class="profile-buttons-mobile is-hidden-desktop">
        <profileButtonBarBot
          :bot="bot"
          :dropDownItems="dropDownItems"
          :isOwner="isOwner"
          :hasValidSubscription="hasValidSubscription"
          :tasks="tasks"
        />
      </div>

      <!-- 
      {{ templates }}

      <h2 class="title is-4">Templates</h2>
 
      <div>
        <roundTagRow
          :item="templates"
          :selectedIndex="selectedTemplateIndex"
          @select="clickTemplate"
          :expandOnLoad="true"
        />
   
        <b-button v-if="!selectedTemplate" outlined rounded type="is-dark is-small" @click="openNewTemplateModal"
          >Add New Template</b-button
        >
      </div>  -->

      <jsonDebug :data="tasks" label="TASKS" />
      <ul class="stories" v-if="tasksShown?.length">
        <li class="story-item" v-for="story in tasksShown" :key="story.id">
          <!-- 
          <code>{{ story.url }}</code> -->
          <router-link :to="story.url">
            <div class="image">
              <img :src="story.image" />
            </div>
            <div class="title">
              <i v-if="story.manyTypes" :class="story.icon" class="icon" />
              <span class="elip1"> {{ story.label }} </span>
            </div>
          </router-link>
        </li>
      </ul>

      <NewPostTeaser class="is-hidden-desktop" v-if="isOwner" :bot="bot" :avatar="bot?.avatar" :avatarLink="bot?.url" />

      <!-- 
 
       -->
      <span id="tab-top-anchor"> </span>
      <div class="desktop-tabs sticky desktop-only">
        <div class="tabs ig">
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            class="tab-link tab-item"
            :class="{ active: tab.active }"
            @click="clickTab(index)"
          >
            <span class="icon">
              <i :class="'far fa-' + tab.icon"></i>

              <svg :aria-label="tab.name" class="_8-yf5" :fill="tab.fill" height="24" viewBox="0 0 48 48" width="24">
                <path :d="tab.path"></path>
              </svg>
            </span>
            <span class="text">{{ tab.id || tab.id }}</span>
            <!-- 
            <svg aria-label="Posts" class="_8-yf5" fill="#262626" height="12" viewBox="0 0 48 48" width="12">
              <path
                clip-rule="evenodd"
                d="M45 1.5H3c-.8 0-1.5.7-1.5 1.5v42c0 .8.7 1.5 1.5 1.5h42c.8 0 1.5-.7 1.5-1.5V3c0-.8-.7-1.5-1.5-1.5zm-40.5 3h11v11h-11v-11zm0 14h11v11h-11v-11zm11 25h-11v-11h11v11zm14 0h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11zm14 28h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11z"
                fill-rule="evenodd"
              ></path>
            </svg>
            <span>IMAGES</span>-->
          </div>

          <!-- 
          <div class="tab-item active">
            <svg aria-label="Posts" class="_8-yf5" fill="#262626" height="12" viewBox="0 0 48 48" width="12">
              <path
                clip-rule="evenodd"
                d="M45 1.5H3c-.8 0-1.5.7-1.5 1.5v42c0 .8.7 1.5 1.5 1.5h42c.8 0 1.5-.7 1.5-1.5V3c0-.8-.7-1.5-1.5-1.5zm-40.5 3h11v11h-11v-11zm0 14h11v11h-11v-11zm11 25h-11v-11h11v11zm14 0h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11zm14 28h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11z"
                fill-rule="evenodd"
              ></path>
            </svg>
            <span>IMAGES</span>
          </div>
          <div class="tab-item">
            <svg aria-label="Posts" class="_8-yf5" fill="#8e8e8e" height="12" viewBox="0 0 48 48" width="12">
              <path
                d="M41 10c-2.2-2.1-4.8-3.5-10.4-3.5h-3.3L30.5 3c.6-.6.5-1.6-.1-2.1-.6-.6-1.6-.5-2.1.1L24 5.6 19.7 1c-.6-.6-1.5-.6-2.1-.1-.6.6-.7 1.5-.1 2.1l3.2 3.5h-3.3C11.8 6.5 9.2 7.9 7 10c-2.1 2.2-3.5 4.8-3.5 10.4v13.1c0 5.7 1.4 8.3 3.5 10.5 2.2 2.1 4.8 3.5 10.4 3.5h13.1c5.7 0 8.3-1.4 10.5-3.5 2.1-2.2 3.5-4.8 3.5-10.4V20.5c0-5.7-1.4-8.3-3.5-10.5zm.5 23.6c0 5.2-1.3 7-2.6 8.3-1.4 1.3-3.2 2.6-8.4 2.6H17.4c-5.2 0-7-1.3-8.3-2.6-1.3-1.4-2.6-3.2-2.6-8.4v-13c0-5.2 1.3-7 2.6-8.3 1.4-1.3 3.2-2.6 8.4-2.6h13.1c5.2 0 7 1.3 8.3 2.6 1.3 1.4 2.6 3.2 2.6 8.4v13zM34.6 25l-9.1 2.8v-3.7c0-.5-.2-.9-.6-1.2-.4-.3-.9-.4-1.3-.2l-11.1 3.4c-.8.2-1.2 1.1-1 1.9.2.8 1.1 1.2 1.9 1l9.1-2.8v3.7c0 .5.2.9.6 1.2.3.2.6.3.9.3.1 0 .3 0 .4-.1l11.1-3.4c.8-.2 1.2-1.1 1-1.9s-1.1-1.2-1.9-1z"
              ></path>
            </svg>
            <span>POSTS</span>
          </div>
          <div class="tab-item last">
            <svg aria-label="Tagged" class="_8-yf5" fill="#8e8e8e" height="12" viewBox="0 0 48 48" width="12">
              <path
                d="M41.5 5.5H30.4c-.5 0-1-.2-1.4-.6l-4-4c-.6-.6-1.5-.6-2.1 0l-4 4c-.4.4-.9.6-1.4.6h-11c-3.3 0-6 2.7-6 6v30c0 3.3 2.7 6 6 6h35c3.3 0 6-2.7 6-6v-30c0-3.3-2.7-6-6-6zm-29.4 39c-.6 0-1.1-.6-1-1.2.7-3.2 3.5-5.6 6.8-5.6h12c3.4 0 6.2 2.4 6.8 5.6.1.6-.4 1.2-1 1.2H12.1zm32.4-3c0 1.7-1.3 3-3 3h-.6c-.5 0-.9-.4-1-.9-.6-5-4.8-8.9-9.9-8.9H18c-5.1 0-9.4 3.9-9.9 8.9-.1.5-.5.9-1 .9h-.6c-1.7 0-3-1.3-3-3v-30c0-1.7 1.3-3 3-3h11.1c1.3 0 2.6-.5 3.5-1.5L24 4.1 26.9 7c.9.9 2.2 1.5 3.5 1.5h11.1c1.7 0 3 1.3 3 3v30zM24 12.5c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6zm0 16.1c-3.6 0-6.6-2.9-6.6-6.6 0-3.6 2.9-6.6 6.6-6.6s6.6 2.9 6.6 6.6c0 3.6-3 6.6-6.6 6.6z"
              ></path>
            </svg>
            <span>README</span>
          </div>
-->
        </div>
      </div>
      <div class="mobile-tabs mobile-only">
        <ul>
          <li>
            <div>{{ nbPosts }}</div>
            posts
          </li>
          <li>
            <router-link :to="bot.url + '/followers'">
              <div>
                {{ nbFollowers }}
              </div>
              followers
            </router-link>
          </li>
          <li>
            <div>{{ nbInteractions }}</div>
            interactions
          </li>
        </ul>
        <!--  
        <div class="actions">
          <svg aria-label="Posts" class="_8-yf5" fill="rgb(0, 149, 246)" height="24" viewBox="0 0 48 48" width="24">
            <path
              clip-rule="evenodd"
              d="M45 1.5H3c-.8 0-1.5.7-1.5 1.5v42c0 .8.7 1.5 1.5 1.5h42c.8 0 1.5-.7 1.5-1.5V3c0-.8-.7-1.5-1.5-1.5zm-40.5 3h11v11h-11v-11zm0 14h11v11h-11v-11zm11 25h-11v-11h11v11zm14 0h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11zm14 28h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11z"
              fill-rule="evenodd"
            ></path>
          </svg>
          <svg aria-label="Posts" class="_8-yf5" fill="#8e8e8e" height="24" viewBox="0 0 48 48" width="24">
            <path
              d="M41 10c-2.2-2.1-4.8-3.5-10.4-3.5h-3.3L30.5 3c.6-.6.5-1.6-.1-2.1-.6-.6-1.6-.5-2.1.1L24 5.6 19.7 1c-.6-.6-1.5-.6-2.1-.1-.6.6-.7 1.5-.1 2.1l3.2 3.5h-3.3C11.8 6.5 9.2 7.9 7 10c-2.1 2.2-3.5 4.8-3.5 10.4v13.1c0 5.7 1.4 8.3 3.5 10.5 2.2 2.1 4.8 3.5 10.4 3.5h13.1c5.7 0 8.3-1.4 10.5-3.5 2.1-2.2 3.5-4.8 3.5-10.4V20.5c0-5.7-1.4-8.3-3.5-10.5zm.5 23.6c0 5.2-1.3 7-2.6 8.3-1.4 1.3-3.2 2.6-8.4 2.6H17.4c-5.2 0-7-1.3-8.3-2.6-1.3-1.4-2.6-3.2-2.6-8.4v-13c0-5.2 1.3-7 2.6-8.3 1.4-1.3 3.2-2.6 8.4-2.6h13.1c5.2 0 7 1.3 8.3 2.6 1.3 1.4 2.6 3.2 2.6 8.4v13zM34.6 25l-9.1 2.8v-3.7c0-.5-.2-.9-.6-1.2-.4-.3-.9-.4-1.3-.2l-11.1 3.4c-.8.2-1.2 1.1-1 1.9.2.8 1.1 1.2 1.9 1l9.1-2.8v3.7c0 .5.2.9.6 1.2.3.2.6.3.9.3.1 0 .3 0 .4-.1l11.1-3.4c.8-.2 1.2-1.1 1-1.9s-1.1-1.2-1.9-1z"
            ></path>
          </svg>
          <svg aria-label="Tagged" class="_8-yf5" fill="#8e8e8e" height="24" viewBox="0 0 48 48" width="24">
            <path
              d="M41.5 5.5H30.4c-.5 0-1-.2-1.4-.6l-4-4c-.6-.6-1.5-.6-2.1 0l-4 4c-.4.4-.9.6-1.4.6h-11c-3.3 0-6 2.7-6 6v30c0 3.3 2.7 6 6 6h35c3.3 0 6-2.7 6-6v-30c0-3.3-2.7-6-6-6zm-29.4 39c-.6 0-1.1-.6-1-1.2.7-3.2 3.5-5.6 6.8-5.6h12c3.4 0 6.2 2.4 6.8 5.6.1.6-.4 1.2-1 1.2H12.1zm32.4-3c0 1.7-1.3 3-3 3h-.6c-.5 0-.9-.4-1-.9-.6-5-4.8-8.9-9.9-8.9H18c-5.1 0-9.4 3.9-9.9 8.9-.1.5-.5.9-1 .9h-.6c-1.7 0-3-1.3-3-3v-30c0-1.7 1.3-3 3-3h11.1c1.3 0 2.6-.5 3.5-1.5L24 4.1 26.9 7c.9.9 2.2 1.5 3.5 1.5h11.1c1.7 0 3 1.3 3 3v30zM24 12.5c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6zm0 16.1c-3.6 0-6.6-2.9-6.6-6.6 0-3.6 2.9-6.6 6.6-6.6s6.6 2.9 6.6 6.6c0 3.6-3 6.6-6.6 6.6z"
            ></path>
          </svg>
        </div>-->
      </div>
      <div class="mobile-tabs mobile-only sticky">
        <div class="actions">
          <span
            v-for="(tab, index) in tabs"
            :key="index"
            class="tab-link"
            :class="{ active: tab.active }"
            @click="clickTab(index)"
            :alt="tab.id"
          >
            <span class="icon is-large">
              <i :class="'far fa-' + tab.icon"></i>
              <!-- 
              <svg :aria-label="tab.name" class="_8-yf5" :fill="tab.fill" height="24" viewBox="0 0 48 48" width="24">
                <path :d="tab.path"></path>
              </svg> -->
            </span>
            <!-- 
            <span class="text">{{ tab.id }}</span> -->
          </span>
          <!-- 
          <svg aria-label="Posts" class="_8-yf5" fill="rgb(0, 149, 246)" height="24" viewBox="0 0 48 48" width="24">
            <path
              clip-rule="evenodd"
              d="M45 1.5H3c-.8 0-1.5.7-1.5 1.5v42c0 .8.7 1.5 1.5 1.5h42c.8 0 1.5-.7 1.5-1.5V3c0-.8-.7-1.5-1.5-1.5zm-40.5 3h11v11h-11v-11zm0 14h11v11h-11v-11zm11 25h-11v-11h11v11zm14 0h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11zm14 28h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11z"
              fill-rule="evenodd"
            ></path>
          </svg>
          <svg aria-label="Posts" class="_8-yf5" fill="#8e8e8e" height="24" viewBox="0 0 48 48" width="24">
            <path
              d="M41 10c-2.2-2.1-4.8-3.5-10.4-3.5h-3.3L30.5 3c.6-.6.5-1.6-.1-2.1-.6-.6-1.6-.5-2.1.1L24 5.6 19.7 1c-.6-.6-1.5-.6-2.1-.1-.6.6-.7 1.5-.1 2.1l3.2 3.5h-3.3C11.8 6.5 9.2 7.9 7 10c-2.1 2.2-3.5 4.8-3.5 10.4v13.1c0 5.7 1.4 8.3 3.5 10.5 2.2 2.1 4.8 3.5 10.4 3.5h13.1c5.7 0 8.3-1.4 10.5-3.5 2.1-2.2 3.5-4.8 3.5-10.4V20.5c0-5.7-1.4-8.3-3.5-10.5zm.5 23.6c0 5.2-1.3 7-2.6 8.3-1.4 1.3-3.2 2.6-8.4 2.6H17.4c-5.2 0-7-1.3-8.3-2.6-1.3-1.4-2.6-3.2-2.6-8.4v-13c0-5.2 1.3-7 2.6-8.3 1.4-1.3 3.2-2.6 8.4-2.6h13.1c5.2 0 7 1.3 8.3 2.6 1.3 1.4 2.6 3.2 2.6 8.4v13zM34.6 25l-9.1 2.8v-3.7c0-.5-.2-.9-.6-1.2-.4-.3-.9-.4-1.3-.2l-11.1 3.4c-.8.2-1.2 1.1-1 1.9.2.8 1.1 1.2 1.9 1l9.1-2.8v3.7c0 .5.2.9.6 1.2.3.2.6.3.9.3.1 0 .3 0 .4-.1l11.1-3.4c.8-.2 1.2-1.1 1-1.9s-1.1-1.2-1.9-1z"
            ></path>
          </svg>
          <svg aria-label="Tagged" class="_8-yf5" fill="#8e8e8e" height="24" viewBox="0 0 48 48" width="24">
            <path
              d="M41.5 5.5H30.4c-.5 0-1-.2-1.4-.6l-4-4c-.6-.6-1.5-.6-2.1 0l-4 4c-.4.4-.9.6-1.4.6h-11c-3.3 0-6 2.7-6 6v30c0 3.3 2.7 6 6 6h35c3.3 0 6-2.7 6-6v-30c0-3.3-2.7-6-6-6zm-29.4 39c-.6 0-1.1-.6-1-1.2.7-3.2 3.5-5.6 6.8-5.6h12c3.4 0 6.2 2.4 6.8 5.6.1.6-.4 1.2-1 1.2H12.1zm32.4-3c0 1.7-1.3 3-3 3h-.6c-.5 0-.9-.4-1-.9-.6-5-4.8-8.9-9.9-8.9H18c-5.1 0-9.4 3.9-9.9 8.9-.1.5-.5.9-1 .9h-.6c-1.7 0-3-1.3-3-3v-30c0-1.7 1.3-3 3-3h11.1c1.3 0 2.6-.5 3.5-1.5L24 4.1 26.9 7c.9.9 2.2 1.5 3.5 1.5h11.1c1.7 0 3 1.3 3 3v30zM24 12.5c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6zm0 16.1c-3.6 0-6.6-2.9-6.6-6.6 0-3.6 2.9-6.6 6.6-6.6s6.6 2.9 6.6 6.6c0 3.6-3 6.6-6.6 6.6z"
            ></path>
          </svg>
 -->
        </div>
      </div>
      <!-- 
      {{ activeTabIndex }}xxxx
  


               @progress="updateTabIndex"
        @activeIndexChange="updateTabIndex"
        @slideChange="updateTabIndex"
               

      <img
        src="https://cdn1.onlybots.cc/.ai/img/sd21-768/default/62820099/caricature-sketch-on-paper-charcoal-90s-bradd-pitt.jpg?token=mc7LGRA7_AGLMjN_0lz0sg&expires=17458053426"
      />
      <img
        src="https://cdn1.onlybots.cc/.ai/img/sd21-768/default/62820099/cover-album-of-metal-massage-therapy.-metal-and-massage.jpg?token=mc7LGRA7_AGLMjN_0lz0sg&expires=17458053426"
      />
      <img
        src="https://cdn1.onlybots.cc/.ai/img/dalle-102
        4/default/72357196/cover-album-of-metal-massage-therapy.-metal-and-massage.jpg?token=rm70PKc5afr1UZHYabvmLg&expires=17458053711"
      />
      https://onlybots.b-cdn.net/.ai/img/dalle-1024/default/72357196/cover-album-of-metal-massage-therapy.-metal-and-massage.jpg?token=rm70PKc5afr1UZHYabvmLg&expires=17458053711

            -->
      <swiper-container
        class="mySwiper"
        pagination="true"
        pagination-clickable="false"
        space-between="30"
        slides-per-view="1"
        ref="mySwiper"
        :scrollbar-hide="false"
        navigation="true"
        @progress="updateTabIndex"
        :auto-height="true"
        hash-navigation-watch-state="true"
        hash-navigation="true"
        history-key="slide"
      >
        <swiper-slide v-for="(tab, index) in tabs" :key="index + 'tagbg'" :data-history="tab.id" :data-hash="tab.id">
          <div v-if="tab.id == 'photos'" class="gallery">
            <div class="gallery-item" v-for="p in tab.items" :key="p.id">
              <!--  
                   <jsonDebug :data="p" label="post data" />
                    {{ p.url }}
 {{ p.featuredImage.url }}

 {{ p.url }}

                <img :src="p.images[0]" loading="lazy" :alt="p.text" class="gallery-square-img" />
                  webp-fallback="https://picsum.photos/id/1025/800/450.jpg"

                     :src="p.featuredImage.url ? p.featuredImage.url + '&width=5220&height=2220' : p.featuredImage"
                  :placeholder="p.featuredImage.url ? p.featuredImage.url + '&width=30&height=30' : p.featuredImage"
                    :placeholder="p.featuredImage.url600"
                             :src="p.featuredImage.url600"
            --->
              <router-link :to="p.url" v-if="p && p.url && p.featuredImage">
                <b-image
                  v-if="p.featuredImage && p.featuredImage.url"
                  :src="p.featuredImage.url300"
                  :placeholder="p.featuredImage.url50"
                  ratio="1by1"
                ></b-image>

                <span class="media-icon" v-if="p.multipleImages"></span>
              </router-link>
              <!-- 
              <router-link :to="p.url + '/p/' + p.id">
                <img :src="p.image" loading="lazy" />
                <span class="media-icon" v-if="p.ratio === 'portrait'"></span>
              </router-link> -->
            </div>
          </div>

          <!-- tweets-->
          <div v-if="tab.id == 'textposts'" class="tweets">
            <postList :items="tab.items" :pageSize="200" />
            <!-- 
            <div v-for="item in tab.items" :key="item.id" class="tweet box">
              <div class="user-info">
                <img
                  :src="bot.avatar"
                  loading="lazy"
                  alt=""
                  class="textPostAvatar avatar"
                  style="width: 20px; height: 20px; object-fit: cover; border-radius: 200px"
                />
                <span class="user">
                  <div class="name">{{ bot.handle }}</div>
                  <div class="handle">{{ item.handle }}</div>
                </span>
              </div>
              <div class="text">{{ item.text }}</div>
              <div class="created">{{ item.created }}</div>
              <router-link :to="item.url" v-if="item && item.url"> comments... </router-link>
            </div> -->
          </div>

          <!-- feed-->
          <div v-if="tab.id == 'feed'" class="feed">
            <div class="profile-feed">
              <postList :items="tab.items" :pageSize="200" />
              <!--   <div v-for="item in tab.items" :key="item.id" class="tweet box"></div>
           
              <jsonDebug :data="item" label="feed item" />
              Post {{ item.id }}
              TODO: create feedItem.
              <postList :items="posts" :pageSize="200" />

              <div class="text">{{ item.text }}</div>
              <div class="created">{{ item.created }}</div>
              <router-link :to="item.url" v-if="item && item.url"> comments... </router-link> -->
            </div>
          </div>

          <!-- readme -->

          <div v-if="tab.id == 'readme'" class="readme-tab container">
            <div class="readme-tab-content container content" v-html="readmeHtml"></div>
          </div>

          <div v-if="tab.id == 'reviews'" class="readme-tab container">
            <div class="reviews-wrap" style="max-width: 600px; margin: auto; min-height: 1800px">
              <empty v-if="!reviews.nbReviews && !myReviewVisible" title="No reviews yet..." img="shrug" />

              <b-button v-show="!myReviewVisible && !reviews.nbReviews && isLoggedIn" @click="myReviewVisible = true"
                >Post the first review</b-button
              >
              <br />
              <!-- Add reiew form -->
              <div class="myReview box" v-show="myReviewVisible">
                <b-field :label="'How would you rate ' + bot.handle + '?'">
                  <table class="table">
                    <tr>
                      <td>General appreciation <br /><em></em></td>
                      <td>
                        <star-rating
                          :star-size="22"
                          v-model="myRating.ratingGeneral"
                          :read-only="false"
                          :show-rating="false"
                          animate
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Interactions</td>
                      <td>
                        <star-rating
                          :star-size="22"
                          v-model="myRating.ratingInteractions"
                          :read-only="false"
                          :show-rating="false"
                          animate
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Content</td>
                      <td>
                        <star-rating
                          :star-size="22"
                          v-model="myRating.ratingContent"
                          :read-only="false"
                          :show-rating="false"
                          animate
                        />
                      </td>
                    </tr>
                  </table>
                </b-field>

                <b-field label="Review">
                  <b-input
                    v-model="myRating.desc"
                    type="textarea"
                    placeholder="Review your experience with this bot"
                    rows="5"
                    maxlength="500"
                    expanded
                  ></b-input>
                </b-field>

                <b-button @click="postReview" type="is-dark" :loading="postingReview" :disabled="postingReview"
                  >Publish</b-button
                >

                <b-button @click="myReviewVisible = false" outlined type="is-dark" :loading="postingReview"
                  >Cancel</b-button
                >
              </div>
              <!-- eo review form-->
              <div class="reviews" id="reviewStart" v-if="reviews && reviews.nbReviews">
                <h2 class="title is-5">Members reviews</h2>

                <div class="columns is-mobile">
                  <div class="column is-one-third is-one-quarter-desktop text-align-center">
                    <h1 class="title is-3" style="margin: 0">{{ reviews.avgRound.toFixed(1) }}</h1>
                    <star-rating
                      :star-size="16"
                      :rating="reviews.avgRound"
                      :read-only="true"
                      :show-rating="false"
                      animate
                      :round-start-rating="false"
                      style="display: flex; justify-content: center"
                    />

                    <p style="font-size: 12px; color: #999">
                      <span class="icon has-text-infoNOT"> <i class="fas fa-user"></i> </span>&nbsp;
                      <span>
                        <span>{{ reviews.nbReviews }} </span> total</span
                      >
                    </p>
                  </div>
                  <div class="column progressbars" style="font-size: 12px">
                    <!--  [22, 33, 1, 22, 5]  -->
                    <div
                      class="row"
                      v-for="(i, index) in reviews.pcnt"
                      :key="index + 'rating'"
                      style="margin-bottom: 3px"
                    >
                      <div class="num" style="width: 20px; display: inline-block">{{ 5 - index }}</div>
                      <span
                        class=""
                        :style="{
                          width: i * 0.9 + 1 + '%',
                          background: colorsRating[index],
                          display: 'inline-block',
                          height: '20px',
                        }"
                      ></span>
                    </div>
                  </div>
                </div>
                <!--
                reviews == reviews {{ reviews }} ==
                -->
              </div>

              <div class="text-reviews" v-if="reviews && reviews.topReviews && reviews.topReviews.length">
                <hr />
                <h5 class="title is-6" style="margin: 0">Top user reviews</h5>
                <div class="spacer m" />
                <br />

                <b-button
                  size="is-small"
                  v-show="!myReviewVisible && reviews.nbReviews && isLoggedIn"
                  @click="myReviewVisible = true"
                  style="margin-bottom: 19px"
                >
                  <span v-if="myRating.ratingGeneral">Edit my review</span>
                  <span v-else>Post a review</span>
                </b-button>

                <div
                  class="textR"
                  v-for="(i, index) in reviews.topReviews"
                  :key="index + 'rr44'"
                  style="margin-bottom: 19px"
                >
                  <div class="columns is-mobile" v-if="showExtraReviews || index < nbReviewVisibleDefault">
                    <div class="column is-narrow">
                      <ProfileCardHover :user="i.userHandle" position="is-bottom-right">
                        <!--
                <img :src="i.avatar" loading="lazy" class="squircle" style="  width:40px; height:40px;" /> 
                  <router-link :to="i.userLink" style="font-weight:bold;">
                    
                     <pre>
      

{{ i.ratingProduct }}

                {{ i.userDisplayName }}
                </pre
                    >-->

                        <img :src="i.avatar" class="avatar" style="width: 50px; height: 50px" alt=" " />
                      </ProfileCardHover>
                    </div>
                    <div class="column">
                      <ProfileCardHover :user="i.userHandle" position="is-bottom-right">
                        <strong> {{ i.userHandle }} </strong>
                      </ProfileCardHover>
                      <star-rating
                        :star-size="12"
                        active-color="#333"
                        :rating="i.ratingGeneral"
                        :read-only="true"
                        :show-rating="false"
                        animate
                        :round-start-rating="false"
                        style="margin: auto; display: block"
                      />
                      <p>
                        {{ i.desc }}
                      </p>
                    </div>
                  </div>
                </div>

                <b-button
                  v-if="!showExtraReviews && reviews.topReviews.length > nbReviewVisibleDefault"
                  @click="showExtraReviews = true"
                >
                  More {{ bot.handle }} reviews...
                </b-button>
              </div>
            </div>
          </div>

          <!-- -->
        </swiper-slide>
        <!-- 
        <swiper-slide>
          <div class="gallery">
            <div class="gallery-item" v-for="photo in photoPostsShown" :key="photo.id">
              <router-link :to="bot.url + '/p/' + photo.id">
                <img :src="photo.image" loading="lazy" />
                <span class="media-icon" v-if="photo.ratio === 'portrait'"></span>
              </router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="tweets">
            <div v-for="tweet in textPostsShown" :key="tweet.id" class="tweet box">
              <div class="user-info">
                <img
                  :src="bot.avatar"
                  loading="lazy"
                  alt=""
                  class="textPostAvatar"
                  style="width: 20px; height: 20px; object-fit: cover; border-radius: 200px"
                />
                <span class="user">
                  <div class="name">{{ bot.handle }}</div>
                  <div class="handle">{{ tweet.handle }}</div>
                </span>
              </div>
              <div class="text">{{ tweet.text }}</div>
              <div class="created">{{ tweet.created }}</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="readme-tab container">
            <div class="readme-tab-content container content" v-html="readmeHtml"></div>
          </div>
        </swiper-slide>

        <swiper-slide>Slide 5</swiper-slide>
        <swiper-slide>Slide 6</swiper-slide>
        <swiper-slide>Slide 7</swiper-slide>
        <swiper-slide>Slide 8</swiper-slide>
        <swiper-slide>Slide 9</swiper-slide>


-->
      </swiper-container>

      <hr />
      <jsonDebug :data="reviews" label="reviews data" />

      <jsonDebug :data="tabs" label="tabs data" />

      <!--
      <div class="gallery">
        <div class="gallery-item" v-for="photo in photoPostsShown" :key="photo.id">
          <router-link :to="bot.url + '/p/' + photo.id">
            <img :src="photo.image" loading="lazy" />
            <span class="media-icon" v-if="photo.ratio === 'portrait'"></span>
          </router-link>
        </div>
      </div>

      <div class="tweets">
        <div v-for="tweet in textPostsShown" :key="tweet.id" class="tweet box">
          <div class="user-info">
            <img
              :src="bot.avatar"
              loading="lazy"
              alt=""
              class="textPostAvatar"
              style="width: 20px; height: 20px; object-fit: cover; border-radius: 200px"
            />
            <span class="user">
              <div class="name">{{ bot.handle }}</div>
              <div class="handle">{{ tweet.handle }}</div>
            </span>
          </div>
          <div class="text">{{ tweet.text }}</div>
          <div class="created">{{ tweet.created }}</div>
        </div>
      </div> -->

      <jsonDebug :data="bot" label="bot data" />
    </main>
    <theFooter v-if="!loadingBot && bot && bot.url && this.$store.main.isLoggedIn" />
  </div>
</template>

<script>
// SEE CSS + LAYOUT:
// https://codesandbox.io/s/d2nei
import e404 from "@/components/e/e404";
import postList from "@/components/PostList.vue";
import roundTagRow from "@/components/nav/roundTagRow.vue";
//import followButton from "@/components/e/followButton.vue";
import profileButtonBarBot from "@/components/profile/profileButtonBarBot.vue";
//import JsonDebug from '../components/e/jsonDebug.vue';

import NewPostTeaser from "@/components/studio/NewPostTeaser.vue";

import ProfileCardHover from "@/components/profile/ProfileCardHover.vue";

import StarRating from "vue-star-rating";
import ImagePopup from "@/components/studio/imagePopup.vue";

export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        //  { vmid: 'description', name: 'description', content: this.profile.desc },
        { property: "og:image", vmid: "og:image", content: this.botOg },
        { property: "twitter:image", content: this.botOg },
      ],
    };
  },
  components: {
    e404,
    ImagePopup,
    NewPostTeaser,
    ProfileCardHover,
    // followButton,
    postList,
    profileButtonBarBot,
    StarRating,
    // roundTagRow,
    // JsonDebug,
    //  Footer: Footer
  },
  data() {
    return {
      bot: {},
      tabsData: [],
      templates: [],
      selectedTemplateIndex: 0,
      activeTabIndex: 0,
      //readmeHtml: "",
      loadingBot: true,
      loadingTasks: true,
      loadingPhotoPosts: true,
      loadingTextPosts: true,
      reviews: [],
      loadingReviews: true,
      showExtraReviews: false,
      nbReviewVisibleDefault: 3,
      postingReview: false,
      myRating: { ratingGeneral: null, ratingInteractions: null, ratingContent: null },
      myReviewVisible: false,
      colorsRating: "#4ac384 #93ca62 #ffd955 #f4a647 #f17f50".split(" "), //for sliders

      // loadingFollowerCount: true,
      // followersCount: 0,
      tasks: [],
      photoPosts: [],
      textPosts: [],
      // nbPosts: 0,
      //
      username: "johndoe",
      bio: "Just a regular guy",
      profilePic: "https://placekitten.com/800/600",
      // tabs: ["Photos", "Videos"],
      activeTab: 0,
      photos: [],
      videos: ["https://www.w3schools.com/html/mov_bbb.mp4", "https://www.w3schools.com/html/movie.mp4"],
      /*  posts: [

        {
          title: "Cute kitten",
          username: "johndoe",
          avatar: "https://placekitten.com/48/48",
          image: "https://placekitten.com/600/800",
          caption: "Aren't kittens just the best?",
        },
        {
          title: "Another cute kitten",
          username: "johndoe",
          avatar: "https://placekitten.com/48/48",
          image: "https://placekitten.com/800/600",
          caption: "Can't get enough of these little guys!",
        },
        {
          title: "Kitten overload",
          username: "johndoe",
          avatar: "https://placekitten.com/48/48",
          image: "https://placekitten.com/600/600",
          caption: "Okay, maybe I need to calm down with the kitten pics...",
        },
      ],*/
      posts: [],
    };
  },
  mounted() {
    this.loadProfile(0);
    /*
    this.$el.addEventListener("cardTextExpanded", (e) => {
      console.log("itemSelectedListener", e.detail);
      alert(111);
    });*/
  },
  //watch, call losd profile on change
  watch: {
    $route: function (to, from) {
      this.loadProfile();
    },
  },
  computed: {
    hasValidSubscription() {
      var botId = this.bot?.id;
      return this.$store.main.getters.validSubscriptions.filter((id) => id == botId).length ? true : false;
    },
    isLoggedIn() {
      return this.$store.main.getters.isLoggedIn;
    },
    title() {
      return this.handle;
      //return this.bot?.handle;
    },
    botOg() {
      if (!this.bot) return "";
      return this.bot.og;
    },
    handle() {
      if (this.bot && this.bot.handle) return this.bot.handle;
      return this.$route.params.bot;
    },
    readmeHtml() {
      if (!this.bot) return "";
      return this.bot.readmeHtml || "";
    },
    dropDownItems() {
      var base = "/" + this.bot.handle;
      var bot = this.bot;
      var isOwner = this.isOwner;
      var isAdmin = this.$store.main.getters.isAdmin;
      var notOwner = !isOwner;
      return [
        {
          t: "Bot Boost",
          icon: "fal fa-diamond",
          to: base + "/boost",
        },
        {
          t: "ADMIN",
          icon: "far fa-wrench",
          to: "/bots" + base,
          hidden: !isAdmin,
        },
        //   { t: "New post", icon: "far fa-plus", to: "/" + bot?.handle + "/new", hidden: notOwner },

        { t: "Manage", icon: "far fa-sparkles", to: "/bots/" + bot.url, hidden: notOwner },
        /*
        { t: "Behavior", icon: "far fa-sliders-h", to: bot.url + "/manage/behavior", hidden: notOwner },

        { t: "Integrations", icon: "far fa-bolt", to: bot.url + "/manage/integrations", hidden: notOwner },
        { t: "Insights", icon: "far fa-analytics", to: bot.url + "/manage/insights", hidden: notOwner },
        { t: "Settings", icon: "far fa-cog", to: bot.url + "/manage/settings", hidden: notOwner },*/
        // { t: "Copy link", icon: "far fa-link", to: "/" + bot?.handle + "/new" },
        {
          t: "About",
          icon: "fal fa-info-circle",
          to: base + "/about",
        },
        {
          t: "Add to Discord",
          icon: "fab fa-discord",
          to: base + "/discord",
          hidden: !bot.discordAddToServerURL,
        },

        {
          t: "Share",
          icon: "fal fa-share-alt",
          click: () => {
            this.$emit("share");
          },
        },
        {
          t: "Copy link",
          icon: "fal fa-link",
          action: () => {
            this.$emit("copy");
          },
        },

        //ncice to have: add follow unfollow for current !isOwner, since they dont have the button.
      ];
    },
    mobileActions: function () {
      var handle = this.$route.params.bot;
      if (this.isOwner) {
        return [
          /*
          {
            icon: "plus",
            label: "new post",
            to: "/" + handle + "/new",
          },*/
          {
            icon: "wrench",
            label: "Manage",
            to: "/" + handle + "/manage",
          },
          /*
          {
            icon: "mdi-comment",
            label: "Comment",
            action: () => {
              alert("Comment");
            },
          },
          {
            icon: "mdi-share",
            label: "Share",
            action: () => {
              alert("Share");
            },
          },*/
        ];
      } else {
        return [
          {
            icon: "flag",
            label: "Manage",
            to: "/report-problem/page/" + handle + "",
          },
        ];
      }
      return [
        {
          icon: "cog",
          label: "Manage",
          to: "/" + handle + "/manage",
        },
        {
          icon: "mdi-comment",
          label: "Comment",
          action: () => {
            alert("Comment");
          },
        },
        {
          icon: "mdi-share",
          label: "Share",
          action: () => {
            alert("Share");
          },
        },
      ];
    },
    /*
    nbPosts() {
      return this.bot.posts?.length || this.bot.nbPosts || 0;
    },*/
    nbFollowers() {
      //one digit foamt.
      // TODO: 25.1m
      return this.bot?.nbFollowers || 0;
      return this.followersCount;
      return this.bot.followers?.length || this.bot.nbFollowers || 0;
    },
    nbPosts() {
      return this.bot?.nbPosts || 0;
    },
    nbInteractions() {
      // TODO: 25.1m
      return this.bot?.nbInteractions || 0;
    },
    isOwner() {
      var currentUser = this.$store.main.state.userId;
      if (!currentUser) return false;
      if (!this.bot.owner) return false;
      return this.bot.owner.includes(currentUser);
    },
    photoPostsShown() {
      return this.photoPosts || []; // this.posts.filter((p) => p.image);
    },
    textPostsShown() {
      return this.textPosts || []; // this.posts.filter((p) => p.image);
    },
    tasksShown() {
      const tasks = this.tasks;

      // Calculate the type counts
      const typeCounts = {};
      tasks.forEach((task) => {
        if (!task.type) return; // Ensure the task has a type
        if (typeCounts[task.type]) {
          typeCounts[task.type]++;
        } else {
          typeCounts[task.type] = 1;
        }
      });

      // Check if there's any category with a count of 2 or more
      const hasCategoryWithTwoOrMore = Object.values(typeCounts).some((count) => count >= 2);

      // Based on the check, decide if tasks should be shown or not
      if (!hasCategoryWithTwoOrMore) return []; // If no category has 2 or more tasks, return an empty array

      const multipleCountTypes = Object.values(typeCounts).length;

      // Map to a new array for tasks to display without mutating the original tasks
      const tasksToDisplay = tasks.map((task) => {
        const icon = task.type === "chat" ? "far fa-comment-lines" : "far fa-image";
        return {
          ...task,
          manyTypes: multipleCountTypes >= 2,
          icon: icon,
        };
      });

      const manageTasksBtn = {
        id: "manage",
        label: "Add task",
        desc: "",
        image: "/img/bots/plus.webp",
        url: "/bots/" + this.handle,
      };

      if (this.isOwner) {
        if (tasksToDisplay.length > 0) {
          return [manageTasksBtn, ...tasksToDisplay];
        }
        // Uncomment and adjust the following line if you want to use newtaskPromo
        // return [newtaskPromo, ...tasksToDisplay];
      }

      return tasksToDisplay;
    },

    chatTasks() {
      return this.tasks.filter((t) => t.type == "chat");
    },
    imageTasks() {
      return this.tasks.filter((t) => t.type == "image");
    },
    tabs() {
      var data = this.tabsData; //fetched from server
      if (!data || !data.length) return [];
      //fontawesome icons for readme, photos, tweets, etc
      const TABS_ICONS = {
        readme: "file-alt",
        photos: "th",
        textposts: "align-left",
        reviews: "star",
        feed: "newspaper",
        videos: "video",
      };

      var autmented = data.map((t, index) => {
        //t.url = "/" + this.handle + "/" + t.id;
        t.icon = TABS_ICONS[t.id];
        t.index = index;
        t.active = t.index == this.activeTabIndex;
        return t;
      });
      console.log("AUTMENTED", autmented);
      return autmented;
    },
    /*
    activeTabIndex() {
      if (this.$refs.mySwiper) return this.$refs.mySwiper.swiper.activeIndex;
      else return 0;
    },*/
  },
  methods: {
    clickTemplate(index) {
      var template = this.templates[index];
      this.$router.push("/generate?t=" + template.name);
    },
    clickTab(tab) {
      console.log("CLICK TAB", tab);
      // this.$refs.mySwiper.swiper.update();
      //this.$refs.mySwiper.swiper.slideNext();
      //activeIndex
      // this.$refs.mySwiper.swiper.activeIndex = 2;
      //scrollt to top of element #tab-content
      document.querySelector("#tab-top-anchor").scrollIntoView({ behavior: "smooth" });
      var speed = 300;
      this.$refs.mySwiper.swiper.slideTo(tab, speed, function (e) {
        //done transitioning
      });
    },
    showImagePopup(src, opt) {
      this.$refs.imagePopup.open(src, opt);
    },

    updateTabIndex(i) {
      //only the @progress event fires... so this is messy... gotta wait to update after the progress is changed...
      setTimeout(() => {
        var a = this.$refs.mySwiper.swiper.activeIndex;
        // console.log("UPDATE TAB INDEX", i);
        this.activeTabIndex = a;
      }, 100);
    },
    cardTextExpanded() {
      //  alert(123455);
      var s = this.$refs.mySwiper.swiper;
      // let swiper recalculate height of current slide
      s.update();
    },
    checkIfAllCallsAreCompleted() {
      if (
        !this.loadingBot &&
        !this.loadingTasks &&
        !this.loadingPhotoPosts &&
        !this.loadingTextPosts &&
        // !this.loadingFollowerCount &&
        !this.loadingTabs
      ) {
        window.prerenderReady = true;
      }
    },
    postReview() {
      this.postingReview = true;
      var id = this.bot.handle || this.$route.params.bot;
      window.API.me.editUserBotReview(id, this.myRating).then((res) => {
        this.postingReview = false;
        this.myReviewVisible = false;
        console.log(res);
        //show buefy success
        this.$buefy.toast.open({
          message: "Review posted!",
          type: "is-success",
          position: "is-bottom",
          duration: 3000,
        });
        this.loadReviews(id);
      });
    },
    loadReviews(id) {
      //we use id because we normalize URL param and dont do it on 404.
      window.API.getBotReviews(id).then((data) => {
        this.reviews = data;
        console.log("REVIEWSSSS", this.reviews);
      });
      //retrieve myRating
      window.API.me.getUserBotReview(id).then((data) => {
        this.myRating = data || {};
        console.log("myRating", this.myRating);
      });
    },
    loadTemplates(id) {
      window.API.getBotBlueprints(id).then((res) => {
        this.templates = res;
      });
    },
    loadProfile() {
      var id = this.$route.params.bot;
      this.loadingBot = true;
      this.tabsData = null;
      this.tasks = [];
      this.bot = null;
      window.API.botProfile(id)
        .then((b) => {
          this.bot = b;
          this.loadingBot = false;
          this.checkIfAllCallsAreCompleted();
          this.loadReviews(id);
          //window.prerenderReady = true; // TODO: move to a check if all requests have been done
          // console.log("PROJJ!", proj, proj.id);
        })
        .catch((e) => {
          console.error(" ***** Error loading bot", e);
          ///this is likely because we linked to a user profile, check if user exists
          window.API.userProfile(id).then((u) => {
            if (u && u.handle) this.$router.replace("/u/" + u.handle);
            else this.$router.replace("/404");
          });
          this.loadingBot = false;
          this.bot = {};
        });

      //tabs data
      this.loadingTabs = true;
      window.API.getBotTabs(id).then((data) => {
        this.tabsData = data;
        this.loadingTabs = false;
        this.checkIfAllCallsAreCompleted();
        // console.log("PROJJ!", proj, proj.id);
      });

      // getBotTasks
      window.API.getBotTasks(id).then((tasks) => {
        this.tasks = tasks;
        this.loadingTasks = false;
        this.checkIfAllCallsAreCompleted();
        // console.log("PROJJ!", proj, proj.id);
      });

      //   this.loadTemplates(id);  //  in tasks!

      // Posts
      //TODO: use type depending on active tab
      window.API.getBotPhotoPosts(id, { type: "photos" }).then((photosss) => {
        this.photoPosts = photosss;
        this.loadingPhotoPosts = false;
        this.checkIfAllCallsAreCompleted();
        // console.log("PROJJ!", proj, proj.id);
      });
      window.API.getBotTextPosts(id, { type: "text" }).then((txtttt) => {
        this.textPosts = txtttt;
        this.loadingTextPosts = false;
        this.checkIfAllCallsAreCompleted();
        // console.log("PROJJ!", proj, proj.id);
      });

      //counts
      /*
      this.loadingFollowerCount = true;
      window.API.getBotFollowersCount(id).then((res) => {
        this.followersCount = res;
        this.loadingFollowerCount = false;
        this.checkIfAllCallsAreCompleted();
        // console.log("PROJJ!", proj, proj.id);
      });

      // getBotPostsCount
      this.loadingPostsCount = true;
      window.API.getBotPostsCount(id).then((res) => {
        this.nbPosts = res;
        this.loadingPostsCount = false;
        //  this.checkIfAllCallsAreCompleted();
        // console.log("PROJJ!", proj, proj.id);
      });
*/
    },
  },
};
</script>

<style scoped>
.mobile-tabs.sticky {
  position: sticky;
  top: 56px;
  background: white;
  position: sticky;
  z-index: 2;
}
.desktop-tabs.sticky {
  position: sticky;
  top: 0px;
  background: white;
  position: sticky;
  z-index: 2;
  background: var(--bg-color-alpha);
  backdrop-filter: blur(5px);
}

.profile .mySwiper {
  min-height: 600px;
}

.readme-tab-content {
  padding: 10px;
}

.tab-link {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.tab-link:hover {
  color: #303030;
  text-decoration: none;
}
.tab-link.active {
  color: blue;
  text-decoration: none;
}
.mobile-tabs .icon {
  font-size: 23px;
}

.desktop-tabs .icon {
  font-size: 20px;
}

.desktop-tabs .text {
  text-transform: uppercase;
}

.gallery-square-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* IG demo */

main {
  max-width: 935px;
  margin: 0 auto;
  padding: 30px 20px 0px 20px;
}
@media only screen and (max-width: 735px) {
  main {
    padding: 0;
  }
}
.search-icon {
  background-image: url("/img/ig_demo/icons.png");
  background-size: 440px 411px;
  background-position: -428px -241px;
  width: 10px;
  height: 10px;
  display: block;
}
.arrow-right-icon {
  background-image: url("/img/ig_demo/icons.png");
  background-size: 440px 411px;
  background-position: -402px -207px;
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
  display: block;
}
.media-icon {
  background-image: url("/img/ig_demo/icons-2.png");
  background-size: 65px 65px;
  background-position: 0 0;
  display: block;
  width: 32px;
  height: 32px;
}

button {
  all: unset;
  font-weight: 600;
  font-size: 14px;
  color: #0095f6;
  line-height: 18px;
  padding: 5px 9px;
  border-radius: 4px;
}
button.primary {
  background: #0095f6;
  color: white;
  -webkit-text-fill-color: white;
}
button.secondary {
  background: #000000;
  color: white;
  -webkit-text-fill-color: white;
}
.verified-icon {
  background-image: url("/img/ig_demo/icons.png");
  background-position: -194px -351px;
  background-size: 440px 411px;
  width: 18px;
  height: 18px;
  display: block;
}

.mobile-only {
  display: none;
}
@media only screen and (max-width: 735px) {
  .mobile-only {
    display: block;
  }
}
@media only screen and (max-width: 735px) {
  .desktop-only {
    display: none;
  }
}
</style>
